import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import TextBanner from '../components/TextBanner';
import ProductListing from '../components/ProductListing';
import { getProductCards } from '../utils';

const ProductsPageTemplate = ({
  data: {
    datoCmsAllProduct,
    shopifyCollection: { shopifyProducts },
    allDatoCmsProduct: { datoCmsProducts },
  },
}) => {
  const { locale, slugLocales, seoMetaTags, title, bannerHeading, bannerText } =
    datoCmsAllProduct;

  const products = getProductCards(datoCmsProducts, shopifyProducts, locale);

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsAllProduct}
    >
      <main>
        <TextBanner title={title} heading={bannerHeading} text={bannerText} />
        <ProductListing items={products} />
      </main>
    </Layout>
  );
};

export const ProductsPageTemplateQuery = graphql`
  query ProductsPageTemplateQuery($id: String!, $locale: String!) {
    datoCmsAllProduct(id: { eq: $id }) {
      locale
      slugLocales: _allSlugLocales {
        locale
        value
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerHeading
      bannerText
      ...LinkFragment
    }
    shopifyCollection(handle: { eq: "all" }) {
      shopifyProducts: products {
        ...ShopifyProductCardFragment
      }
    }
    allDatoCmsProduct(filter: { locale: { eq: $locale } }) {
      datoCmsProducts: nodes {
        ...DatoCmsProductCardFragment
      }
    }
  }
`;

export default ProductsPageTemplate;
